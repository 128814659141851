
import { PageBase } from '@/core/models/shared';
import { RelatorioService } from '@/core/services/financeiro';
import { Component } from 'vue-property-decorator';

@Component
export default class RelatorioReceita extends PageBase { 

    service = new RelatorioService();
    item = new Relatorio();
    overlay: boolean = false;
    filter:any = {};

    mounted() { 
        this.filter = this.$route.query;
        this.overlay = true;
        
        this.service.ListarContratosEmAtrasos(this.filter.empreendimentoId, this.filter.numeroContrato, this.filter.mesesVencimentoInicio, this.filter.mesesVencimentoFim,this.filter.quantidadeParcelasMinima, this.filter.quantidadeParcelasMaxima,  this.filter.empresaId, this.filter.diasVencimentoInicio, this.filter.diasVencimentoFim)
            .then(
                res => { 
                    this.item = res.data;
                },
                err=>{
                    if (!err.response) {
                        this.$swal("Aviso", "Não foi possível acessar a API", "error");
                    } 
                    else {
                        this.$swal("Aviso", err.response.data, "error");
                    }
                }
            ).finally(() =>{
                this.overlay = false;
            });
    } 
}
 
class Relatorio {
    public contratoNumero:number = 0;
    public diasParcelaMaisAtrasada:number = 0;
    public quantidadeParcelasVencidas:number = 0;
    public mesesParcelaMaisAtrasada:number = 0;
    public valorParcelaMaisAtrasada:number = 0;
    public dataParcelaMaisAtrasada:string =  "";
    public quantidadeParcelasParaQuitacao:number =0;
    public valorDebitoVencido:number = 0;
    public valorDebitoReceitaContrato:number =0
    public situacaoContrato:string = "";
    public empreendimentoNome:string = "";
    public quadra:string = "";
    public lote:string = "";
    public nomeCliente:string = "";
    public telefoneCliente:string = "";
    public emailCliente:string = "";
}

